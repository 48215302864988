<template>
<div>
  <TopMenuSubheadline :title="title" />
  <div class="app-content-box bg-gray" ref="container">
    <div class="container" v-if="!isLoading">
      <div class="msg-profile ">
        <div class="pt-3 row">
          <div class="col-4 pr-0">
            <router-link :to="{name: 'OtherUserProfile', params: {id: id}}">
              <div class="msg-profile-avatar">
                <img :src="user_profile.thumb_url ? plainUrl + user_profile.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="">
              </div>
            </router-link>
          </div>
          <div class="col-8">
            <router-link :to="{name: 'OtherUserProfile', params: {id: id}}">
              <div class="msg-profile-name">{{user_profile.username}}</div>
            </router-link>
            <!-- <div class="msg-profile-status">
              obecnie czyta
              <strong>The Lord of the Rings</strong>
            </div> -->
          </div>
      </div>
     </div>
    </div>
    <div class="container message-container-wrapper" ref="container">
      <div class="msg-box" v-for="(text, key) in messages" :key="key">
        <div class="msg incoming" v-if="text.user_id == id">
          <a href="#" class="msg-avatar">
            <img :src="user_profile.thumb_url ? plainUrl + user_profile.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="">
          </a>
          <div class="msg-content">
            {{text.content}}
          </div>
        </div>
        <div class="msg outgoing" v-else>
          <a href="#" class="msg-avatar">
            <img :src="my_profile.thumb_url ? plainUrl + my_profile.thumb_url : require('../assets/avatar-placeholder.jpg')" alt="">
          </a>
          <div class="msg-content">
            {{text.content}}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="chat-scroll-bottom"></div>
  <div class="floating-msg-box" v-if="!blocked">
    <div class="container">
      <div class="add-msg-box">
        <form action="">
          <div class="add-msg-group">
            <textarea v-model="message" name="" id="" cols="30" rows="1" placeholder="Rozpocznij rozmowę..." class="autogrow"></textarea>
            <button @click.prevent="submit" type="submit"><i class="fas fa-paper-plane"></i></button>
          </div>
        </form>
      </div>
      </div>
  </div>
  <div class="blocked-alert-container" v-else>
  <div class="blocked-alert bg-alert pt-1 pb-1">
    <p>Ten użytkownik jest przez Ciebie zablokowany. Nie może z Tobą pisać, nie może komentować Twoich recenzji oraz nie pojawia się w wynikach wyszukiwania. Jeśli chcesz to zmienić <router-link to="/moje-konto/zablokowani" class="text-dark">odblokuj tego użytkownika</router-link></p>
  </div>
</div>
</div>
</template>
<script>
import TopMenuSubheadline from '../components/TopMenuSubheadline'
export default {
  name: 'Messages',
  components: {
    TopMenuSubheadline
  },
  props: {
    id: [String, Number]
  },
  data () {
    return {
      title: 'Chat',
      message: '',
      page: '',
      messages: [],
      scrolled: false,
      my_profile: [],
      user_profile: [],
      isLoading: true,
      blocked: false,
      actionCounter: 0
    }
  },
  async created () {
    await this.$https('/profile/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.user_profile = data.response[0].items
        this.title = 'Chat ' + this.user_profile.username
        if (data.response[0].is_blocked_by_me === true) {
          this.blocked = true
        }
        this.isLoading = false
      })
      .catch(error => {
        console.log('', error)
      })

    await this.getMessages()
  },
  updated () {
    if (!this.scrolled) {
      this.scrollToEnd()
      this.scrolled = true
    }
  },
  watch: {
    actionCounter: function () {
      setTimeout(() => document.getElementById('chat-scroll-bottom').scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' }), 100)
    }
  },
  computed: {
    newNotification: {
      get () {
        return this.$store.state.newNotification
      },
      set (value) {
        this.$store.commit('updateNewNotification', value)
      }
    }
  },
  methods: {
    scrollToEnd () {
      window.scrollTo(0, document.body.scrollHeight)
    },
    getMessages ($state) {
      const getData = `action=getbyuser&user=${this.id}`
      this.$https('/messages', {
        method: 'POST',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        },
        data: getData
      })
        .then(({ data }) => {
          this.messages = data.response.messages
          this.my_profile = data.response.users[0]
          this.user_profile = data.response.users[1]
          this.newNotification = this.newNotification + 1
        })
        .catch(error => {
          console.log('', error)
        })
    },
    submit () {
      if (this.message.trim().length) {
        const getData = `action=add&user=${this.id}&content=${this.message}`
        this.$https('/messages', {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
          },
          data: getData
        })
          .then(res => {
            this.getMessages()
            this.message = ''
            this.actionCounter = this.actionCounter + 1
          })
          .catch(error => {
            console.log('', error)
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
 .app-content-box {
   min-height: 100vh;
 }
  .blocked-alert {
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  border: 1px solid #f5c6cb;
  background-color: #f8d7da;
  color: #721c24;
  box-sizing: border-box;
  font-size: 14px;

    &-container {
      position: fixed;
      top: 250px;
    }
  }
  .message-container-wrapper {
    padding-bottom: 100px;
  }
  .floating-add-comment, .floating-msg-box {
    bottom: 60px;
  }
  @media screen and (max-width: 320px) {
    .msg-profile .msg-profile-avatar {
      width: 70px;
      height: 70px;
    }
    img {
      vertical-align: top;
    }
  }
</style>
